<template>
  <b-row>
    <b-col cols="12">
      <b-card
        no-body
        class="mb-0"
      >
        <permission-rules-add
          :is-add-new-permission-rule-sidebar-active.sync="isAddNewPermissionRuleSidebarActive"
          :subject-options="subjectOptions"
          :action-options="actionOptions"
          @refetch-data="refetchData"
        />
        <div class="m-2">
          <b-row>
            <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Показывать по</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>записей</label>
            </b-col>
            <b-col
              cols="12"
              md="3"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <v-select
                v-model="subjectFilter"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="subjectOptions"
                class="w-100"
                :reduce="val => val.value"
              />
            </b-col>
            <b-col
              cols="12"
              md="5"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
                />
                <b-button
                  variant="primary"
                  @click="isAddNewPermissionRuleSidebarActive = true"
                >
                  <span class="text-nowrap">Добавить правило</span>
                </b-button>
              </div>
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refPermissionRuleListTable"
          class="position-relative"
          :items="fetchItems"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Совпадающих записей не найдено"
          :sort-desc.sync="isSortDirDesc"
        >

          <template #cell(module)="data">
            {{ data.value }}
          </template>

          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="editRules(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Редактировать</span>
              </b-dropdown-item>

              <b-dropdown-item @click="deleteRules(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Удалить</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Показано с {{ dataMeta.from }} по {{ dataMeta.to }} из {{ dataMeta.of }} элементов</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BTable, BFormInput, BButton, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import PermissionRulesAdd from './components/PermissionRulesAdd.vue'
import permissionRuleList from './components/permissionRuleList'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    PermissionRulesAdd,
  },
  data() {
    return {
      fields: [
        { key: 'subject', label: 'Модуль' },
        { key: 'action', label: 'Действие' },
        { key: 'conditions', label: 'Ограничения' },
        { key: 'type', label: 'Тип правила' },
        { key: 'title', label: 'Наименование' },
      ],
    }
  },
  methods: {
    async deleteRules(id) {
      const result = await this.$swal({
        title: 'Подтверждение удаления!',
        text: 'Вы уверены в необходимости удалить роль?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Отмена',
        confirmButtonText: 'Удалить',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
      if (result.value) {
        this.deleteItem(id, () => {
          this.$swal({
            icon: 'success',
            title: 'Роль успешно удалена!',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })
        })
      }
    },
    async editRules({ id, title } = {}) {
      const chat = this
      this.$swal({
        title: 'Search for a user',
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputValue: title,
        inputAttributes: {
          autocapitalize: 'off',
        },
        showCancelButton: true,
        cancelButtonText: 'Отмена',
        confirmButtonText: 'Обновить',
        showLoaderOnConfirm: true,
        preConfirm(_title) {
          if (!_title) return null
          return chat.updateTitle(id, _title)
            .then()
            .catch(error => {
              chat.$swal.showValidationMessage(
                `Ошибка обеовления: ${error}`,
              )
            })
        },
      }).then(result => {
        if (result.value) {
          this.$swal({
            icon: 'success',
            title: 'Роль успешно обновлена!',
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-success',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
  setup() {
    const isAddNewPermissionRuleSidebarActive = ref(false)
    const {
      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPermissionRuleListTable,
      subjectOptions,
      actionOptions,

      resolveActionVariant,
      refetchData,
      updateTitle,
      deleteItem,

      // Extra Filters
      subjectFilter,
    } = permissionRuleList()

    return {
      isAddNewPermissionRuleSidebarActive,

      fetchItems,
      tableColumns,
      perPage,
      currentPage,
      totalItems,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPermissionRuleListTable,
      subjectOptions,
      actionOptions,

      resolveActionVariant,
      refetchData,
      updateTitle,
      deleteItem,

      // Extra Filters
      subjectFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
