<template>
  <b-sidebar
    id="add-new-permission-rule-sidebar"
    :visible="isAddNewPermissionRuleSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-permission-rule-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Добавить новое правило
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name=" "
            rules="required"
          >
            <b-form-group
              label="Модуль"
              label-for="subject"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="rulesData.subject"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="subjectOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="subject"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="  "
            rules="required"
          >
            <b-form-group
              label="Действие"
              label-for="action"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="rulesData.action"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="actionOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="action"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
            label="Ограничения"
            label-for="conditions"
          >
            <b-form-input
              id="conditions"
              v-model="rulesData.conditions"
              trim
            />
          </b-form-group>

          <validation-provider
            #default="validationContext"
            name="   "
            rules="required"
          >
            <b-form-group
              label="Тип правила"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="rulesData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
            label="Название"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="rulesData.title"
              trim
            />
          </b-form-group>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Добавить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отмена
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import permissionRepository from '@/repository/permissionRepository'
import { required } from '@validations'
import store from '@/store'

localize('ru', ru)

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    BButton,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPermissionRuleSidebarActive',
    event: 'update:is-add-new-permission-rule-sidebar-active',
  },
  props: {
    isAddNewPermissionRuleSidebarActive: {
      type: Boolean,
      required: true,
    },
    subjectOptions: {
      type: Array,
      default: () => ([]),
    },
    actionOptions: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props, { emit }) {
    const blankData = {
      action: null,
      subject: null,
      fields: null,
      conditions: null,
      type: null,
      title: '',
    }

    const rulesData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetRulesData = () => {
      rulesData.value = JSON.parse(JSON.stringify(blankData))
    }

    const typeOptions = [
      { label: 'can', value: 'can' },
      { label: 'cannot', value: 'cannot' },
    ]

    const onSubmit = async () => {
      try {
        await permissionRepository.addRules(rulesData.value)
        emit('refetch-data')
        emit('update:is-add-new-permission-rule-sidebar-active', false)
        store.commit('app/SET_MESSAGE', 'Правило успешно создано.')
      } catch (error) {
        store.commit('app/SET_ERROR', error)
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRulesData)

    return {
      rulesData,
      onSubmit,
      typeOptions,

      refFormObserver,
      getValidationState,
      resetForm,

      required,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
