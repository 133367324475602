import { ref, watch, computed } from '@vue/composition-api'
import permissionRepository from '@/repository/permissionRepository'
import store from '@/store'

export default function permissionRuleList() {
  const refPermissionRuleListTable = ref(null)

  const tableColumns = [
    { key: 'subject', label: 'Модуль', sortable: true },
    { key: 'action', label: 'Действие' },
    { key: 'conditions', label: 'Ограничения', sortable: true },
    { key: 'type', label: 'Тип правила' },
    { key: 'title', label: 'Наименование', sortable: true },
    { key: 'actions', label: '', sortable: false },
  ]

  const perPage = ref(10)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('subject')
  const isSortDirDesc = ref(true)
  const subjectFilter = ref(null)
  const subjectOptions = ref([])
  const actionOptions = ref([])
  const selectRules = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refPermissionRuleListTable.value ? refPermissionRuleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItems.value,
    }
  })

  const refetchData = () => {
    refPermissionRuleListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, subjectFilter], () => {
    refetchData()
  })

  const fetchItems = (ctx, callback) => {
    permissionRepository.rulesList({
      query: searchQuery.value,
      page: currentPage.value,
      limit: perPage.value,
      sord: isSortDirDesc.value ? 'desc' : 'asc',
      sidx: sortBy.value,
      subject: subjectFilter.value,
    })
      .then(response => {
        const { items, count } = response

        callback(items)
        totalItems.value = count
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }

  permissionRepository.modulesList({
    sord: 'asc',
    sidx: 'subject',
  })
    .then(res => {
      subjectOptions.value = (res || []).length > 0 ? res.map(x => ({ label: x.subject, value: x.subject })) : []
    })
  permissionRepository.actionsList({
    sord: 'asc',
    sidx: 'id',
  })
    .then(res => {
      actionOptions.value = (res || []).length > 0 ? res.map(x => ({ label: x.action, value: x.action })) : []
    })

  const deleteItem = (id, callback) => {
    permissionRepository.deleteRule(id)
      .then(() => {
        callback()
        refetchData()
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
      })
  }

  const updateTitle = (id, title) => new Promise((resolve, reject) => {
    const param = new FormData()
    param.append('title', title)
    permissionRepository.updateRule(id, param)
      .then(res => {
        refetchData()
        resolve(res)
      })
      .catch(error => {
        reject(error)
      })
  })

  const clearSelectTable = () => {
    refPermissionRuleListTable.value.clearSelected()
  }

  const selectRowTable = val => {
    selectRules.value = val
  }

  const resolveActionVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  return {
    fetchItems,
    tableColumns,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPermissionRuleListTable,
    subjectOptions,
    actionOptions,

    resolveActionVariant,
    refetchData,
    updateTitle,
    deleteItem,
    clearSelectTable,
    selectRowTable,

    // Extra Filters
    subjectFilter,
    selectRules,
  }
}
