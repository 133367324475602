import conect from './api'

const { axiosIns } = conect()

export default {
  async rulesList(params = {}) {
    const { error, result } = (await axiosIns.get('/permission/rules', { params })).data
    if (error) throw error

    return result
  },
  async addRules(...params) {
    const { error, result } = (await axiosIns.post('/permission/rules', ...params)).data
    if (error) throw error

    return result
  },
  async updateRule(id, ...args) {
    const { error, result } = (await axiosIns.put(`/permission/rules/${id}`, ...args)).data
    if (error) throw error

    return result
  },
  async deleteRule(id) {
    const { error, result } = (await axiosIns.delete(`/permission/rules/${id}`)).data
    if (error) throw error

    return result
  },
  async modulesList(params = {}) {
    const { error, result } = (await axiosIns.get('/permission/modules', { params })).data
    if (error) throw error

    return result
  },
  async actionsList(params = {}) {
    const { error, result } = (await axiosIns.get('/permission/actions', { params })).data
    if (error) throw error

    return result
  },
  async rolesList(params = {}) {
    const { error, result } = (await axiosIns.get('/permission/roles', { params })).data
    if (error) throw error

    return result
  },
  async getRole(id) {
    const { error, result } = (await axiosIns.get(`/permission/roles/${id}`)).data
    if (error) throw error

    return result
  },
  async roleRuleList(params = {}) {
    const { error, result } = (await axiosIns.get('/permission/role-rules', { params })).data
    if (error) throw error

    return result
  },
  async deleteRuleFromRole(id) {
    const { error, result } = (await axiosIns.delete(`/permission/role-rules/${id}`)).data
    if (error) throw error

    return result
  },
  async addRuleFromRole(...args) {
    const { error, result } = (await axiosIns.post('/permission/role-rules/', ...args)).data
    if (error) throw error

    return result
  },
}
